.timetable {
  max-width: 1240px;
  margin: auto;
  padding-top: 24px;
  min-height: calc(100vh - 276px);
}

.timetable-container {
  display: grid;
  grid-template-columns: 7fr 3fr;
  gap: 32px;
  padding-top: 12px;
}

.semester-pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 32px;
}

.arrow-disable {
  color: var(--grey-secondary);
}

.arrow-active:hover {
  cursor: pointer;
}

@media (max-width: 1240px) {
  .timetable {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media (max-width: 768px) {
  .timetable-container {
    grid-template-columns: 1fr;
    gap: 24px;
  }
}
