.no-data-feedback {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  margin-top: 24px;
}

.no-data-feedback .icon {
    fill: var(--yellow-primary);
}
