.timetable-day-column .col-container .col-item .schedule-item {
  position: absolute;
  width: 100%;
  border-radius: 8px;
  border: 0.5px solid var(--white-primary);
  padding: 4px;
}

.timetable-day-column .col-container .col-item .schedule-item p {
  font-family: "Inter";
  font-weight: 400;
  font-size: 10px;
}

.timetable-day-column .col-container .col-item .schedule-item .course-name {
  font-family: "Inter";
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
}

.clickable:hover {
  cursor: pointer;
}

@media (max-width: 560px) {
  .timetable-day-column .col-container .col-item .schedule-item p {
    font-size: 10px;
    line-height: 12px;
  }

  .timetable-day-column .col-container .col-item .schedule-item .course-name {
    font-size: 10px;
    line-height: 12px;
  }
}
