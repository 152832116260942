.footer {
  background-color: var(--black-primary);
  margin-top: 52px;
}

.footer-container {
  max-width: 1240px;
  margin: auto;
  padding: 52px 0px;
}

.footer-text {
  font-size: 14px;
  color: var(--white-secondary);
}

.footer-link {
  font-weight: 600;
  text-decoration: underline;
}

.footer-link:hover {
  color: var(--yellow-primary);
}

@media (max-width: 1240px) {
  .footer-container {
    padding: 52px 16px;
  }
}
