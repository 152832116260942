.button-add-course-grid {
  border: 1px solid var(--grey-primary);
  border-radius: 8px;
  display: grid;
  grid-template-columns: 7fr 1fr;
}

.button-add-course {
  border: 1px solid var(--grey-primary);
  border-radius: 8px;
}

.dropdown-left-col {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: auto;
  justify-items: center;
  align-items: center;
  padding: 8px 0px;
  border-radius: 7px 0px 0px 7px;
  transition: background-color 0.1s;
}

.dropdown-left-col:hover {
  background-color: var(--yellow-primary);
  cursor: pointer;
}

.dropdown-left-col:hover p {
  color: var(--black-primary);
}

.dropdown-right-col {
  display: flex;
  width: 100%;
  height: 100%;
  border-left: 1px solid var(--grey-primary);
  border-radius: 0px 7px 7px 0px;
  margin: auto;
  justify-content: center;
  align-items: center;
  transition: background-color 0.1s;
}

.dropdown-right-col:hover {
  background-color: var(--yellow-primary);
  cursor: pointer;
}

.dropdown-menu {
  margin-top: 2px;
  border-radius: 8px;
  box-shadow: -4px 2px 15px 1px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: -4px 2px 15px 1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: -4px 2px 15px 1px rgba(0, 0, 0, 0.2);
}

.dropdown-sem-item {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 8px 0px;
}

.dropdown-sem-item p {
  padding: 0px 8px;
  text-align: center;
}

.dropdown-sem-item:hover {
  background-color: var(--yellow-primary);
  cursor: pointer;
}

.dropdown-sem-item:hover p {
  color: var(--black-primary);
}
