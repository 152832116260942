.timetable-semester-course {
  display: grid;
  grid-template-columns: min-content 1fr;
}

.days {
  display: flex;
  flex-direction: row;
  overflow: auto;
}
