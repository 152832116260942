.course-container {
  margin-top: 24px;
  margin-bottom: 48px;
}

.header-course-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: end;
}

.title-container {
  display: flex;
  flex-direction: column;
}

.title-container p {
  padding-top: 14px;
}

.sem-container {
  display: flex;
  padding-top: 14px;
  flex-direction: row;
  align-items: center;
  gap: 7px;
}

.sem-wrapper {
  background-color: var(--yellow-primary);
  width: fit-content;
  padding: 8px 20px;
  border-radius: 30px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
}

.course-desc {
  padding-top: 14px;
}

.title-container a :hover {
  color: var(--yellow-primary);
}

@media (max-width: 768px) {
  .sem-wrapper {
    padding: 6px 16px;
    font-weight: 600;
    font-size: 12px;
  }
}
