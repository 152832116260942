@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

:root {
  --yellow-primary: #ffd900;
  --yellow-secondary: #ffc803;
  --black-primary: #292929;
  --grey-primary: #757575;
  --grey-secondary: #aeb1b5;
  --white-primary: #ffffff;
  --white-secondary: #f6f6f6;

  --red-primary: #d9334b;
  --red-secondary: #e36a7c;
  --red-dark: #771c29;

  --orange-primary: #fb6e52;
  --orange-secondary: #fc9581;
  --orange-dark: #8a3d2d;

  --sun-primary: #ffce55;
  --sun-secondary: #ffdb83;
  --sun-dark: #8c712f;

  --green-primary: #a0d468;
  --green-secondary: #bae091;
  --green-dark: #587539;

  --mint-primary: #48cfae;
  --mint-secondary: #79dcc4;
  --mint-dark: #287260;

  --sea-primary: #4ebfe7;
  --sea-secondary: #7ed0ed;
  --sea-dark: #2b697f;

  --lightbrown-primary: #b1614e;
  --lightbrown-secondary: #c68c7e;
  --lightbrown-dark: #61352b;

  --pastel-primary: #edc1b6;
  --pastel-secondary: #f2d2ca;
  --pastel-dark: #826a64;

  --blue-primary: #3575a8;
  --blue-secondary: #6c9abf;
  --blue-dark: #1d405c;

  --brown-primary: #875c36;
  --brown-secondary: #a7886c;
  --brown-dark: #4a331e;

  --purple-primary: #c5bbf1;
  --purple-secondary: #d5cdf5;
  --purple-dark: #6c6785;

  --darkblue-primary: #385068;
  --darkblue-secondary: #6e7f91;
  --darkblue-dark: #1f2c39;

  --toastify-font-family: "inter" !important;
}

h1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  color: var(--black-primary);
}

h2 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  color: var(--black-primary);
}

h3 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: var(--black-primary);
}

h5 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: var(--black-primary);
}

h6 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: var(--black-primary);
}

p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: var(--grey-primary);
}

@media (max-width: 768px) {
  h5 {
    font-size: 18px;
  }

  p {
    font-size: 14px;
  }
}
