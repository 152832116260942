.timetable-export-wallpaper {
  width: 600px;
  height: 1299px;
}

.download-page {
  background-color:white;
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.download-illustration {
  width: 30%;
}

@media (max-width: 560px) {
  .download-illustration {
    width: 50%;
  }
}

.download-page a {
  text-decoration: underline;
  cursor: pointer;
}

.download-page a:hover {
  color: var(--yellow-primary);
}
