.share-button {
    padding: 10px 20px;
    border-radius: 5px;
    border: 1px solid var(--black-primary);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }
  
  .share-button:hover {
    cursor: pointer;
    background-color: var(--yellow-primary);
    border: 1px solid var(--yellow-primary);
  }
  
  .share-button p {
    color: var(--black-primary);
    font-size: 14px;
  }
  