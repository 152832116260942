.filter-mobile .icon {
  background-color: var(--yellow-primary);
  height: fit-content;
  width: fit-content;
  padding: 14px;
  border-radius: 28px;

  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 98;
}

.filter-mobile-active {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: var(--white-primary);
  transition-property: all;
  transition-timing-function: ease-in-out;
  transition-duration: 500ms;
  padding: 24px 24px 92px 24px;
  overflow: auto;
}

.filter-mobile-inactive {
  position: fixed;
  left: 0;
  bottom: -100%;
  width: 100%;
  height: 100%;
  background-color: var(--white-primary);
  transition-property: all;
  transition-timing-function: ease-in-out;
  transition-duration: 500ms;
  padding: 24px 24px 92px 24px;
  overflow: auto;
}

@media (min-width: 768px) {
  .filter-mobile {
    display: none;
  }
}
