.checkbox-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
}

.checkbox-container:hover {
  background-color: var(--yellow-primary);
}

.checkbox-container label {
  cursor: pointer;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: var(--grey-primary);
}

.container-input {
  cursor: pointer;
  width: 24px;
  height: 24px;
  position: relative;
  margin-left: 8px;
}

.container-input-before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: var(--black-primary);
  background-color: var(--white-primary);
  border: 1.3px solid var(--grey-secondary);
  border-radius: 5.2px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.container-input-after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: var(--black-primary);
  background-color: var(--yellow-primary);
  border: 1.3px solid var(--black-primary);
  border-radius: 5.2px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.container-input-cancel {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: var(--white-primary);
  background-color: #FF1F00;
  border: 1.3px solid var(--black-primary);
  border-radius: 5.2px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.container-input > input {
  appearance: none;
}
