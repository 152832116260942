.download-container {
  position: relative;
}

.download-button {
  padding: 10px 20px;
  border-radius: 5px;
  border: 1px solid var(--black-primary);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.download-button:hover {
  cursor: pointer;
  background-color: var(--yellow-primary);
  border: 1px solid var(--yellow-primary);
}

.download-button p {
  color: var(--black-primary);
  font-size: 14px;
}

.download-menu {
  background-color: var(--white-primary);
  z-index: 99;
  border-radius: 8px;
  box-shadow: -4px 2px 15px 1px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: -4px 2px 15px 1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: -4px 2px 15px 1px rgba(0, 0, 0, 0.2);
  padding: 8px 0px;
  position: absolute;
  width: 100%;
  top: 105%;
}

.download-item {
  padding: 4px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  color: var(--grey-primary);
}

.download-item:hover {
  background-color: var(--yellow-primary);
  cursor: pointer;
  color: var(--black-primary);
}

.download-item p {
  font-size: 14px;
}

.download-item:hover p {
  color: var(--black-primary);
}
