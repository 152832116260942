.day-column {
  border: 0.5px solid var(--grey-secondary);
  flex: 1;
}

.day-label {
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--white-primary);
  padding: 8px 0px;

  font-family: "Inter";
  font-weight: 600;
  font-size: 14px;
  color: var(--grey-primary);
}

.day-column .col-container {
  background-image: repeating-linear-gradient(
    to bottom,
    var(--white-secondary),
    var(--white-secondary) 56px,
    var(--white-primary) 56px,
    var(--white-primary) 112px
  );
  display: flex;
  flex-direction: row;
}

.day-column .col-container .col-item {
  position: relative;
  min-width: 64px;
  flex: 1;
}

.day-column .col-container .col-item .schedule-item {
  position: absolute;
  width: 100%;
  border-radius: 8px;
  border: 0.5px solid var(--white-primary);
  padding: 4px;
}

.day-column .col-container .col-item .schedule-item p {
  font-family: "Inter";
  font-weight: 400;
  font-size: 10px;
}

.day-column .col-container .col-item .schedule-item .course-name {
  font-family: "Inter";
  font-weight: 600;
  font-size: 12px;
}

.empty {
  min-width: 64px;
  max-width: 64px;
}
