.course-details {
  max-width: 1240px;
  margin: auto;
  padding-top: 24px;
  min-height: calc(100vh - 276px);
}

.course-details h3 {
  padding-top: 8px;
}

.course-details-container {
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 32px;
}

.sem-container-details {
  display: flex;
  padding-top: 4px;
  flex-direction: row;
  align-items: center;
  gap: 7px;
}

.section-container {
  margin: 12px 0px;
}

.line {
  display: flex;
  justify-content: end;
  padding-top: 18px;
  border-bottom: 1px solid var(--grey-secondary);
}

.line-timetable {
  display: flex;
  justify-content: end;
  padding-top: 8px;
  border-bottom: 1px solid var(--grey-secondary);
}

@media (max-width: 1240px) {
  .course-details {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media (max-width: 768px) {
  .course-details-container {
    grid-template-columns: 1fr;
    gap: 0px;
  }

  .prerequisite {
    margin: 12px 0px 0px 0px;
  }
}
