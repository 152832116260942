.search-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 24px;
  gap: 12px;
  background-color: var(--white-secondary);
  border-radius: 42px;
  width: 100%;
}

.search-input {
  border: none;
  width: 100%;
  background-color: var(--white-secondary);

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

.search-input:focus {
  outline: none;
}

.search-icon {
  color: var(--black-primary);
}

.search-icon-focused {
  color: var(--yellow-primary);
}

.search-count {
  display: flex;
  justify-content: end;
  border-bottom: 1px solid var(--grey-secondary);
}

.search-count p {
  padding-top: 16px;
  padding-bottom: 8px;
  font-size: 14px;
}

@media (max-width: 768px) {
  .search-count p {
    font-size: 12px;
  }
}
