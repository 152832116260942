.loading-page {
  height: 100vh;
  width: 100hh;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
