.course-item {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: stretch;
  margin-top: 18px;
  min-height: fit-content;
}

.course-item .color-box {
  flex: 0 0 auto;
  width: 4px;
  border-radius: 2px;
}

.course-item .details {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.course-item .details .info {
  flex: 1;
  padding-right: 8px;
  position: relative;
}

.course-item .course-name {
  font-size: 14px;
  font-weight: 600;
  color: var(--black-primary);
  width: fit-content;
}

.course-item .course-name:hover {
  cursor: pointer;
  color: var(--yellow-primary);
}

.course-item .class-name {
  font-size: 12px;
  font-weight: 600;
}

.course-item .instructor {
  font-size: 12px;
}

.course-item .details .icons {
  display: flex;
  flex-direction: row;
  color: var(--grey-primary);
}

.course-item .details .icons .icon-wrapper {
  padding: 8px;
  border-radius: 4px;
}

.course-item .details .icons .icon-wrapper:hover {
  cursor: pointer;
  color: var(--white-primary);
  background-color: var(--grey-primary);
}

.course-item .class-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 8px;
}

.course-item .class-click .dropdown-class {
  fill: var(--grey-primary);
}

.course-item .class-click:hover {
  cursor: pointer;
  background-color: var(--white-secondary);
}

.course-item .class-click:hover .dropdown-class {
  fill: var(--black-primary);
}

.course-item .class-click:hover p {
  color: var(--black-primary);
}

.dropdown-class-item {
  background-color: var(--white-primary);
  position: absolute;
  z-index: 99;
  bottom: 100;
  top: 110%;
  right: 3%;
  width: 100%;
  padding: 8px;
  border-radius: 8px;
  box-shadow: -4px 2px 15px 1px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: -4px 2px 15px 1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: -4px 2px 15px 1px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  gap: 4px;
}
