nav {
  background-color: var(--yellow-primary);
}

.nav-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 1240px;
  height: 75px;
  margin: auto;
}

.nav-logo {
  height: 50px;
}

.nav-menu {
  display: none;
  flex-direction: row;
  gap: 24px;
}

.navlink {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: var(--grey-primary);
}

.navlink:hover {
  color: var(--black-primary);
}

.active {
  color: var(--black-primary);
  font-weight: 700;
}

.nav-mobile-active {
  position: fixed;
  left: 0;
  top: 59px;
  width: 100%;
  height: 100%;
  background-color: var(--yellow-primary);
  transition-property: all;
  transition-timing-function: ease-in-out;
  transition-duration: 500ms;
  z-index: 99;
}

.nav-mobile-inactive {
  transition-timing-function: ease-in-out;
  transition-duration: 500ms;
  position: fixed;
  left: -100%;
  top: 59px;
  width: 60%;
  height: 100%;
}

.nav-mobile-active li {
  padding: 10px 20px;
}

.nav-mobile-inactive li {
  padding: 10px 20px;
}

.hamburger {
  color: var(--black-primary);
}

@media (max-width: 1240px) {
  .nav-container {
    padding: 0px 24px;
  }
}

@media (min-width: 560px) {
  .nav-menu {
    display: flex;
  }

  .hamburger {
    display: none;
  }
}

@media (max-width: 560px) {
  .nav-container {
    height: 60px;
  }

  .nav-logo {
    height: 40px;
  }
}
