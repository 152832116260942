.add-course-input {
  padding: 10px 20px;
  border-radius: 5px;
  border: 1px solid var(--grey-secondary);
  background-color: var(--white-primary);
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
}

.add-course-input p {
  font-size: 14px;
}
