.semester-button {
  padding: 8px 20px;
  border: 1px solid var(--grey-primary);
  border-radius: 6px;
  display: flex;
  justify-items: center;
  align-items: center;
  width: max-content;
}

.semester-button, .semester-button-active p {
  color: var(--black-primary);
}

.semester-button:hover {
  border: 1px solid var(--white-primary);
  background-color: var(--yellow-primary);
  cursor: pointer;
}

.semester-button-active {
  padding: 8px 20px;
  border-radius: 6px;
  display: flex;
  justify-items: center;
  align-items: center;
  width: max-content;
  border: 1px solid var(--white-primary);
  background-color: var(--yellow-primary);
}
