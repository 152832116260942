.courses-container {
  display: grid;
  grid-template-columns: 2fr 5fr;
  max-width: 1240px;
  margin: auto;
  padding-top: 24px;
  gap: 32px;
}

.MuiPagination-ul li .Mui-selected {
  color: var(--black-primary);
  font-weight: 700;
  background-color: var(--yellow-primary);
}

.MuiPagination-ul li .Mui-selected:hover {
  color: var(--black-primary);
  font-weight: 700;
  background-color: var(--yellow-primary);
}

.MuiPagination-ul li button {
  font-family: "Inter";
  font-weight: 600;
  color: var(--grey-primary);
}

.MuiPagination-root {
  background-color: var(--white-primary);
}

.MuiPaginationItem-ellipsis {
  display: none;
}

.right-column {
  flex-grow: 1;
}

@media (max-width: 1240px) {
  .courses-container {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media (max-width: 768px) {
  .courses-container {
    grid-template-columns: 1fr;
  }

  .left-column {
    display: none;
  }
}
