.instructor-list {
    display: flex;
    flex-direction: row;
    gap: 46px;
    margin-top: 12px;
}

.instructor-list h5 {
    font-weight: 600;
}

.instructor-list .instructor-container {
    padding-top: 8px;
}