.download-share-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 8px;
  padding-top: 8px;
}

.line-timetable-action {
  display: flex;
  justify-content: end;
  padding-top: 20px;
  border-bottom: 1px solid var(--grey-secondary);
}

.total-credit {
  padding-top: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.total-credit h6 {
  font-size: 15px;
  font-weight: 600;
  color: var(--grey-primary);
}

.total-credit p {
  font-size: 15px;
  font-weight: 400;
  color: var(--grey-secondary);
}

.legend {
  margin-top: 18px;
}

.legend p {
  font-size: 12px;
}

.legend p bold {
  font-weight: 600;
}