.filter-clear {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.filter {
  font-weight: 500;
  font-size: 20px;
  color: var(--black-primary);
}

.clear {
  font-weight: 500;
  font-size: 14px;
  color: var(--grey-primary);
}

.filter-box {
  margin-top: 12px;
  background-color: var(--white-secondary);
  height: min-content;
  padding-bottom: 8px;
}

.filter-box-container {
  padding: 0px 8px;
}

.title {
  font-weight: 500;
  font-size: 16px;
  color: var(--black-primary);
  padding-left: 12px;
  padding-bottom: 8px;
  padding-top: 20px;
}
