.time-label {
  display: grid;
  grid-template-rows: repeat(12, 56px);
  padding-top: 27px;
  padding-right: 6px;

  font-family: "Inter";
  font-weight: 600;
  font-size: 13px;
  color: var(--grey-primary);
}
